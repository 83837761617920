import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { bool } from 'prop-types'
import { ReactComponent as Logo } from '../images/Logo.svg'
import { ReactComponent as Search } from '../images/searchicon.svg'
import { useOnClickOutside } from '../utils/clickOutsideHook'
import Burger from './Burger'
import './dropdown.css';
import { ReactComponent as CaretIcon } from '../images/carret.svg'

const StyledHeader = styled.div`
  height: 15ch;
  width: 100vw;
  max-width: 100%;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 8ch;
  }
`
export const BurgerMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.body};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 6;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  display: ${({ open }) => (open ? 'flex' : 'none')};
  transition: transform 0.3s ease-in-out;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    font-size: 3rem;
  }

  
  a {
    font-size: 1rem;
    text-transform: uppercase;
    text-align: center;
    padding: 2rem 0;
    letter-spacing: 0.5rem;
    color: ${({ theme }) => theme.text};
    text-decoration: none;
    transition: color 0.3s linear;
    
    @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: .4rem;
    font-size: .9rem;
  }

    &:hover {
      color: ${({ theme }) => theme.hover};
    }
  }
`

const StyledLinks = styled.div`
  position: relative;
  width: 80ch;
  max-width: 95%;
  margin: 2rem auto;
  margin-top:2rem;
  margin-bottom:3rem;
  display: flex;
  justify-content: space-between;
  z-index: 10;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: none;
  }
  a {
    margin-left: 0rem;
    text-transform: uppercase;
  }
  svg {
    margin-top: 4px;
    fill: var(--gray-900);
    width: 20px;
    height: 20px;
  }
`
const LinkLogo = styled(Link)`
  display: flex;
  justify-content: center;
  margin-top: 1.8rem;
  margin-bottom: -10px;


  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: -1rem;
    svg {
      margin-top: 0rem;
      max-width: 50%;
    }
  }
`
const DesignBtn = styled.button`
  font-size: .9rem;
  border: none;
  outline: none;
  background-color: inherit;
  color:var(--gray-900);
  font-family: inherit;
  text-transform: uppercase;
    text-align: center;
    padding: .4rem 0;
    letter-spacing: 0.5rem;
    color: ${({ theme }) => theme.text};
    text-decoration: none;
    transition: color 0.3s linear;
    svg {
    margin-top: 4px;
    fill: var(--gray-900);
    width: 20px;
    height: 20px;
  }

    &:hover {
      color: ${({ theme }) => theme.hover};
    }
`
const DesignSubmenu = styled(BurgerMenu)`
  transform: ${({ show }) => (show ? 'translateX(0)' : 'translateX(100%)')};
  display: ${({ show }) => (show ? 'flex' : 'none')};
`

const Header = () => {
  const [open, setOpen] = React.useState(false)
  const [show, setShow] = React.useState(false)

  const node = React.useRef()
  useOnClickOutside(node, () => setOpen(false))
  return (
    <StyledHeader>
      <Burger open={open} setOpen={setOpen} />
      <BurgerMenu open={open}>
        <a id="Home" href="/">
          Home
        </a>
        <DesignBtn onClick={() => setShow(true)}>
          Design
           <CaretIcon />
          <DesignSubmenu show={show}>
            <a href="/architecture">Architecture</a>
            <a href="/interior">Interior</a>
            <a href="/commercial">Commercial</a>
            <a href="/web">Web Design</a>
            <a href="/packaging">Packaging</a>
            <a href="/graphic">Graphic</a>
            <a href="/typography">Typography</a>
          </DesignSubmenu>
        </DesignBtn>
        <a id="video" href="/video">
          Video
        </a>
        <a id="store" href="/store">
          Store
        </a>
        <a id="contact" href="/contact">
          Contact
        </a>
      </BurgerMenu>
      <LinkLogo to="/">
        <Logo />
      </LinkLogo>
      <StyledLinks>
        <a id="Home" href="/">
          Home
        </a>
        <div className="subnav">
          <button className="subnavbtn">Design
          <CaretIcon /></button>
          <div className="subnav-content">
            <a href="/architecture">Architecture</a>
            <a href="/interior">Interior</a>
            <a href="/commercial">Commercial</a>
            <a href="/web">Web Design</a>
            <a href="/packaging">Packaging</a>
            <a href="/graphic">Graphic</a>
            <a href="/typography">Typography</a>
          </div>
        </div>

        <a id="video" href="/video">
          Video
        </a>
        <a id="store" href="/store">
          Store
        </a>
        <a id="contact" href="/contact">
          Contact
        </a>
        <Search />
      </StyledLinks>
    </StyledHeader>
  )
}
Header.propTypes = {
  open: bool.isRequired,
}
export default Header
