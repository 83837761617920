
export const lightTheme = {
    body: 'white',
    text: 'var(--gray-900)',
    tag: '#0C0C0C',
    tagtext: 'white',
    toggleBorder: 'var(--gray-800)',
    background: '#FCFCFC',
    hover: 'var(--blue-900)',
    shadow: 'rgba(0, 0, 0, 0.25)',
    svg: 'var(--orange)',
    mobile: '1000px',
    footer: 'white',
}

