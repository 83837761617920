import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
#gatsby-focus-wrapper{
    width:100vw;
    max-width:98%;
  }
  :root {
    --gray-900: #1a202c;
    --gray-800: #2d3748;
    --gray-700: #4a5568;
    --gray-600: #718096;
    --gray-500: #a0aec0;
    --gray-400: #cbd5e0;
    --red-500: #f56565;
    --red-600: #e53e3e;
    --red-700: #c53030;
    --red-800: #9b2c2c;
    --red-900: #742a2a;
    --blue-500: #4299e1;
    --blue-600: #3182ce;
    --blue-700: #2b6cb0;
    --blue-800: #2c5282;
    --blue-900: #2a4365;
    --white: white;
    --red: #f56565;
    --text-decoration-color: var(--gray-400);
    --text-color: var(--gray-800);
    --focus-ring-color: var(--blue-500);
  }
  /* https://css-tricks.com/simplified-fluid-typography/ */
  /* link-ul de mai sus arata f n=bune cum se seteaza fluid typography */
  /* clamp(min, desired, maximum) */
  html, body {
      
      margin: 0;
      padding: 0;
      font-size: clamp(100%, 1rem + 2vw, 24px);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    } 
  
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  
  body {
      width: 100vw;
      max-width: 100%;
      padding:0;
      margin:0;
      display: grid;
      grid-template-columns: 1fr;
      background: ${({ theme }) => theme.body};
      color: ${({ theme }) => theme.text}; 
      font-size: clamp(100%, 1rem + 2vw, 24px);
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-rendering: optimizeLegibility;
      overflow-x: hidden;

  }
  p{
    margin-block-start:0;
    margin-block-end: 0;
    margin-inline-end: 0;
  }
  
    a {
      color: ${({ theme }) => theme.text};
      text-decoration: none;
    }  

    ul{
      list-style: none;
      margin: 0;
      padding: 0;
    }
    button{
        color: ${({ theme }) => theme.text};
        background: ${({ theme }) => theme.body};
        border: 0.5px solid ${({ theme }) => theme.text};
        border-radius: 3px;
        padding: 6px 16px;
        font-size: .7rem;
    }    
`