import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Instagram } from '../images/instagram.svg'
import { ReactComponent as Facebook } from '../images/facebook-round-color.svg'


const FooterGroup = styled.div`
  background: ${({ theme }) => theme.footer};
  padding: 50px 0;
  display: grid;
  grid-gap: 20px;
`

const Text = styled.p`
  font-size: 24px;
  color: ${({ theme }) => theme.text};
  max-width: 600px;
  margin: 0 auto;
  text-align: center;

  a {
    color: var(--blue-500);
    font-size: 1rem;
    font-weight: 500;
    margin: 5px 0;
    transition: 1s;
  }

  a:hover {
    color: ${({ theme }) => theme.text};
  }
`
const SocialIcons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 10px;
  font-size: 24px;
  border: none;
  padding: 16px 60px;
  font-weight: 600;
  justify-self: center;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

  a:hover {
    height: 40px;
    border-radius: 90px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
    transform: translateY(-3px);
  }
  svg {
    height: 2rem;
  }
`

const Copyright = styled.div`
  color: #486791;
  max-width: 500px;
  margin: 0 auto;
  padding: 0 20px;
`

const Footer = () => (
  <FooterGroup>
    <Text>
      We're glad you're here. Email us to ask anything: {' '} <br />
      office@nuebo.ro
    </Text>
    <SocialIcons>
      <a
        href="https://www.facebook.com/NUEBO-architectural-design-studio-451704648211969/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Facebook />
      </a>
      <a
        href="https://www.instagram.com/nuebodesign/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Instagram />
      </a>

    </SocialIcons>

    <Copyright>
      Designed by Nuebo{' '}©2020
    </Copyright>
  </FooterGroup>
)

export default Footer
